/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Root variables */
:root {
  --theme-color: #000000;
  --primary-color: #FF7715;
  --dark-color: #212121;
  --card-bg: #F4F2ED;
  --gray-bg: #F5F5F5;
  --text-blue: #062349;
}

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  overflow-x: hidden;
}

/* Headings */
h1,
h2 {
  color: var(--theme-color);
}

p {
  line-height: 1.6;
  padding: 1rem 0 1rem;
  font-weight: 500;
}

a {
  color: var(--dark-color);
  text-decoration: none;
}

.ant-menu-submenu-selected::after {
  border-bottom-width: 0px !important;
  border-bottom-color: white !important;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: #1677ff !important;
}

.title {
  font-size: 42px !important;
}

/* Font */
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

p {
  color: #000;
  font-size: 14px;
  line-height: 2.5;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-size: 16px;
}

.theme-color {
  background: var(--primary-color);
}

.theme-text {
  color: var(--primary-color);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.space {
  padding: 5rem 0 5rem;
}

.header .logo {
  height: 64px;
  color: white;
  font-size: 24px;
  line-height: 64px;
}

.menu-bar {
  position: absolute;
  width: 100%;
  float: left;
  background: #0000003d;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 6rem;
  height: 0;
}

.logo {
  text-align: center;
}

.logo img {
  height: 90px;
  vertical-align: middle;
}

.ant-menu {
  background: none !important;
  border-bottom: none;
  justify-content: center;
}

.ant-menu-item {
  color: rgba(255, 255, 255, 0.85);
  font-weight: bold;
}

.ant-menu {
  line-height: 64px;
  float: right;
}

.ant-menu-item {
  font-size: 16px;
}

.banner {
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
}

.banner h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.banner p {
  font-size: 18px;
  margin-bottom: 30px;
}

.banner button {
  margin: 5px;
}

.header .logo img {
  height: 90px;
  margin-right: 10px;
  vertical-align: middle;
}

.banner {
  background-image: url('../src/assets/images/vedio.jpg');
  /* Replace with the correct path to your banner image */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  height: 100vh;
  width: 100%;
}

.about-us h2 {
  font-size: 36px;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.about-bg {
  background: url('../src/assets/images/about-bg.png');
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.dotleft::before {
  content: url('../src/assets/images/dot-corner.svg');
  position: absolute;
  top: -5rem;
  left: -5rem;
}

.about-us-bottom::after {
  content: url('../src//assets//images/l-right.svg');
  position: absolute;
  bottom: -5rem;
}

.mobileLeft::after {
  content: url('../src//assets//images/left-dots.svg');
  position: absolute;
  bottom: -2rem;
  right: -5rem;
}

.part {
  height: 5px;
  background: var(--theme-color);
  width: 100px;
}

.services h2 {
  font-size: 36px;
  color: var(--primary-color);
}

.services .ant-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services .ant-card p {
  font-size: 14px;
}

.services .ant-card-cover img {
  width: 100%;
  height: 200px;
  /* Adjust height as needed */
  object-fit: cover;
  border-radius: 8px;
}

.dot-left {
  height: 120px;
  background: var(--primary-color);
  width: 30px;
}

.download-app h2 {
  font-size: 36px;
  color: #ff6f61;
  margin-bottom: 30px;
}

.download-app button {
  margin: 10px;
  font-size: 18px;
}

.download-app img {
  width: 100%;
  height: auto;
  max-width: 400px;
  margin-top: 30px;
}

.testimonials {
  background: #F6F6F6;
}

.middle-user{
  margin-top: -2rem;
}

small {
  color: #96989B;
  font-size: 12px !important;
}

.egg-1 {
  background: url('../src/assets/images/egg-left.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 350px !important;
  width: 100%;
  border: none;
  background-color: transparent !important;
}

.egg-2 {
  background: url('../src/assets/images/egg-middle.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 350px !important;
  width: 100%;
  border: none;
  background-color: transparent;
}

.egg-3 {
  background: url('../src/assets/images/egg-right.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 350px !important;
  width: 100%;
  border: none;
  background-color: transparent;
}

.testimonial-card .ant-card {
  background: none !important;
  border: none;
}

.testimonial-card .ant-card-body {
  padding: 0 3rem;
  background: transparent;
}

.testimonials h2 {
  font-size: 45px;
  color: var(--primary-color);
  transform: rotate(-90deg);
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.testimonials .ant-card p {
  font-size: 16px;
}

.contact-us {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.contact-us h2 {
  font-size: 36px;
  color: var(--primary-color);
}

.contact-us .ant-form-item {
  margin-bottom: 20px;
}

.contact-us button {
  width: 100%;
  font-size: 18px;
  padding: 10px;
}


.contact-us {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
  /* Replace with the correct path */
  background-size: cover;
  background-position: center;
}

.contact-us .contact-image {
  margin-bottom: 30px;
}

.contact-us .contact-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.contact-form label {
  color: var(--primary-color) !important;
  font-size: 40px !important;
  font-weight: bold;
  padding-right: 15px;
}

.contact-form .ant-input {
  height: 70px;
  background: #F6F6F6;
  border: 1px solid #D9D9D9;
}

.contact-form textarea {
  height: 120px !important;
}

.contact-form .ant-btn {
  width: 190px;
  height: 50px;
  border-radius: 45px;
  float: left;
  background: var(--primary-color) !important;
}


.center-heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: var(--theme-color);
  padding: 5px;
  text-align: center;
  color: white;
}

.footer p {
  margin: 0;
  font-size: 14px;
}

.footer-bg .ant-menu .ant-menu-title-content {
  color: var(--theme-color) !important;
}

.footer .logo img {
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

/* ////ant menu */

.menu-bar {
  position: absolute;
  width: 100%;
  float: left;
  background: #0000003d;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.ant-menu {
  line-height: 64px;
  float: initial;
  border: none;
}

.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected {
  color: #fff;
  background-color: none;
}

.ant-menu {
  background: none !important;
}

.ant-menu .ant-menu-title-content {
  color: rgba(255, 255, 255, 0.65);
  font-weight: bold;
}

.ant-card {
  background: #F6F6F6;
}

.ant-card .ant-card-head {
  text-align: center;
  background: transparent;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}

.ant-card-head-title {
  color: var(--primary-color);
}

.float-right {
  float: right;
}

.ant-form-item .ant-form-item-label>label::after {
  content: "";
}

.text-blue {
  color: var(--text-blue);
  letter-spacing: 1px !important;
}

::placeholder {
  color: #000000b9 !important;
}

.ant-menu-submenu::after {
  position: relative !important;
}

.ant-menu-item {
  padding-left: 30px !important;
  padding-right: 30px !important;
}