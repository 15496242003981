/* Responsivness  media queries*/

@media screen and (max-width: 840px) {
  body{
    overflow-x: hidden;
  }
  .m-none{
    display: none;
  }
  .banner h1 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  .space {
    padding: 1rem 0 1rem;
  }
  .dotleft::before {
   display: none;
  }
  .m-pe-0{
    padding-right: 0!important;
  }
  .title {
    font-size: 30px !important;
    font-weight: bolder !important;
  }
  .mobileLeft::after {
   display: none;
  }
  .m-pt-0{
    padding-top: 0px!important;
  }
  .m-pb-0{
    padding-bottom: 0px!important;
  }
  .testimonials h2 {
    transform: inherit;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .contact-us {
    padding-top: 2.5rem;
  }
  .contact-us h2{
    text-align: center;
  }
  .contact-img{
    width: 50%!important;
    margin-bottom: 2.5rem;
  }
  .m-ps-0{
    padding-left: 1rem!important;
  }
  .middle-user{
    margin-top: 0;
  }
  .egg-1 {
    height: 425px !important;
  }
  .egg-2 {
    height: 425px !important;
  }
  .egg-3 {
    height: 425px !important;
  }
}